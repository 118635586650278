@font-face {
  font-family: 'ProximaNova';
  src: local('MyFont'), url(../fonts/ProximaNova-Regular.otf) format('woff');
}

@font-face {
  font-family: 'NTR';
  src: local('MyFont'), url(../fonts/NTR-Regular.ttf) format('woff');
}

@font-face {
  font-family: 'Montserrat';
  src: local('MyFont'), url(../fonts/Montserrat-Regular.ttf) format('woff');
}

@font-face {
  font-family: 'Montserrat-medium';
  src: local('MyFont'), url(../fonts/Montserrat-Medium.ttf) format('woff');
}

@font-face {
  font-family: 'Montserrat-bold';
  src: local('MyFont'), url(../fonts/Montserrat-Bold.ttf) format('woff');
}